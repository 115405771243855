import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div style={{ height: '100vh', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 80, }}>
                <div style={{ flex: 1, display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ fontSize: 30, }}>{props.data ? props.data.paragraph : "Loading"}</p>
                  <p>现已上架小米、OPPO、VIVO、华为、三星应用市场，可以直接在应用市场中搜索【七点工具箱】下载</p>
                  <a
                      href="https://www.coolapk.com/apk/935654"
                      target="_blank"
                      className="btn btn-custom btn-lg page-scroll"
                  >
                    点击下载
                  </a>{" "}
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                  <img width={297} height={528} src="img/screen1.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
