import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>邂逅七点工具箱</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-8 col-md-4">
                  {" "}
                  <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <i className={d.icon}></i>
                    <img width={64} style={{ position: 'absolute', alignSelf: 'center', top: 20, }} src={d.foreground} />
                  </div>
                  <h3>{d.title}</h3>
                  <p style={{ lineHeight: 2, }}>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
